import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/index'
  },{
    path: '/privacy',
    name: 'Privacy',
    component: () => import('../views/privacy.vue'),
    meta: {
      title: "隐私政策"
    }
  },{
    path: '/user',
    name: ' User',
    component: () => import('../views/user.vue'),
    meta: {
      title: "用户协议"
    }
  },{
    path: '/loading',
    name: ' Loading',
    component: () => import('../views/loading.vue'),
    meta: {
      title: "用户协议"
    }
  },{
    path: '/notice',
    name: 'Notice',
    component: () => import('../views/notice.vue'),
    meta: {
      title: "注销用户须知"
    }
  },{
    path: '/dzdk',
    name: 'Dzdk',
    component: () => import('../views/dzdk.vue'),
    meta: {
      title: "chatwow"
    }
  }, {
    path: '/404',
    name: '404',
    component: () => import('../views/404.vue'),
    meta: {
      title: "页面没有找到"
    }
  },
  {
    path: "*",
    redirect: "/404"
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router